import { Collaboration } from "@/-sections/Collaboration";
import { Footer } from "@/-components/Footer";
import { HeroHome } from "@/-sections/Heroes/Home";
import { JoinBanner } from "@/-sections/JoinBanner";
import Logos from "@/-sections/Logos";
import { PricingCards } from "@/-sections/Pricing/PricingCards"
import { Products } from "@/-sections/Products";
import { Testimonials } from "@/-sections/Testimonials";

const { Box, DarkMode } = require("@chakra-ui/react");

const IndexPage = () => {

  return (
    <DarkMode>
      <Box w="full" bg="white">
        <Box w="full" bg="blue.600" py={{ base: 10, lg: 20 }} pb={{ base: 24, lg: 40 }}>
          <HeroHome
            subheading="Plan and collaborate on website structures and customer journeys, with Rarchy's website planning tools. Designed for agencies & teams."
          />
        </Box>
        <Box w="full" bg="blue.600">
          <Box w="full" borderTopRadius="56px" bgColor="white">
            <Logos />
          </Box>
        </Box>
        <Products />
        <Collaboration />
        <Testimonials />
        <Box py={{ base: 0, md: 16 }}><PricingCards /></Box>
        <JoinBanner bgColor="#034024" />
      </Box>
      <Footer bgColor="#034024" />
    </DarkMode>

  )
}

export async function getStaticProps() {
  return { props: { isStatic: true } }
}

export default IndexPage