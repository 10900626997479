import { Box, HStack, Heading, Image, Link, SimpleGrid, Stack, Text } from "@chakra-ui/react"

import { ArrowForwardIcon } from "@chakra-ui/icons"
import ChakraNextImage from "@/-components/ChakraNextImage"
import NextLink from "next/link"
import { ProductsData } from "./_data"
import { useIsDesktop } from "../../../../shared/hooks"

export const Items = () => {
    const isDesktop = useIsDesktop()
    return (
        <Stack
            spacing={{ base: 20, md: 28 }}
            justify="space-between"
            py={{ base: 20, md: 36 }}
        >
            {ProductsData?.map(item => {
                return (
                    <SimpleGrid key={item?.label} columns={{ base: 1, md: 2 }} gap={{ base: '6', md: '40' }}>
                        {isDesktop && <TextSide item={item} />}
                        {isDesktop && <ImageSide item={item} />}
                        {!isDesktop && <ImageSide item={item} />}
                        {!isDesktop && <TextSide item={item} />}
                    </SimpleGrid>
                )
            })
            }
        </Stack>
    )
}

const ImageSide = ({ item }) => {
    return (
        <ChakraNextImage
            as={Image}
            boxShadow="md"
            borderRadius="lg"
            width="full"
            height="full"
            objectFit="cover"
            src={item?.imgSrc}
            alt={item?.label}
            border="1px solid"
            borderColor="gray.500"
        />
    )
}

const TextSide = ({ item }) => {
    return (
        <Stack spacing={{ base: '4', md: '6' }} my="auto">
            <Stack spacing={{ base: '4', md: '6' }}>
                <Heading
                    align="start"
                    size={{ base: 'xs', md: 'sm' }}
                    color="fg.default"
                >
                    {item?.label}
                </Heading>
                <Text
                    align="start"
                    fontSize={{ base: 'md', md: 'lg' }}
                    color="fg.muted"
                >
                    {item?.description}
                </Text>
            </Stack>
            <Link
                as={NextLink}
                align="start"
                href={item?.href}
                color="fg.default"
                fontWeight="semibold"
                w="fit-content"
                _hover={{ textDecor: "none" }}
            >
                <Box data-group role="group">
                    <HStack>
                        <Box
                            color="rarchy-link"
                            fontSize={{ base: "md", md: "lg" }}
                            textDecor="underline"
                            textUnderlineOffset={4}
                            transition="250ms"
                            _hover={{ textDecorationColor: "transparent" }}
                        >
                            {`Explore ${item?.label}`}
                        </Box>
                        <ArrowForwardIcon
                            color="rarchy-link"
                            fontSize="lg"
                            mt={1}
                            transition="250ms"
                            _groupHover={{ ml: 1 }}
                        />
                    </HStack>
                </Box>
            </Link>
        </Stack>
    )
}