export const ProductsData = [
    {
        label: "Visual Sitemaps",
        description: "Our powerful visual sitemap editor helps you to plan and collaborate on your website structure, page content and information architecture.",
        href: "/sitemaps",
        imgSrc: "/images/products/visual-sitemaps.webp"
    },
    {
        label: "Visual Sitemap Generator",
        description: "Generate your visual sitemaps by running a fresh crawl of your website, importing an existing XML sitemap, or uploading a CSV file of your pages.",
        href: "/sitemaps/visual-sitemap-generator",
        imgSrc: "/images/generator-steps/import.webp"
    },
    {
        label: "User Flows",
        description: "Create sophisticated user flows and customer journeys, from potential landing page pathways to existing key conversion journeys.",
        href: "/user-flow",
        imgSrc: "/images/products/user-flow.webp"
    }
]