import { Box, Container } from "@chakra-ui/react"

import { Headline } from "../../-components/Headline"
import { Items } from "./Items"

export const Products = () => {
    return (
        <Box
            transition="500ms"
            as="section"
            id="products"
            w="full"
            mx="auto"
            align="center"
            mt={12}
        >
            <Container w="full" maxW="6xl">
                <Headline
                    heading="Intuitive website planning tools"
                    subheading="A real-time collaborative platform for professionals, teams and agencies to plan their website structure, create complex user journeys and much more."
                    textAlign="center"
                    mx="auto"
                />
                <Items />
            </Container>
        </Box>
    )
}